<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程中心</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="searchData.courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="培训类型" class="searchboxItem ci-full">
              <span class="itemLabel">培训类型:</span>
              <el-cascader
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="searchData.trainTypeId"
                :options="trainTypeList"
                clearable
                size="small"
              ></el-cascader>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div
              class="grid-content bg-purple"
              :class="item.projectStudyTimeState == '60' ? 'shuiyin':''"
              v-for="(item, index) in tableData"
              :key="index"
              @click="goInfo(item)"
            >
              <img class="g_1" :src="item.logo" alt="" />
              <p class="g_2">{{ item.courseName }}</p>
              <!-- <p class="g_3">
                ￥<span>{{ item.price }}</span
                >元/人
              </p> -->
              <p class="g_4">
                适用区域：<span>{{ item.areaNamePath }}</span>
              </p>
              <p class="g_5">
                <span>{{ item.lessonNum }}</span
                >课时
              </p>
              <div
                class="shuiyin-item"
                v-show="item.projectStudyTimeState == '60'"
              >
                培训结束
              </div>
            </div>
            <img
              style="
                width: 10.9375rem;
                display: block;
                margin: auto;
                margin-top: 20%;
              "
              v-if="tableData.length < 1"
              src="../../assets/empty.png"
              alt=""
            />
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "/web/courseCenter",
  components: {
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchData: {
        // 课程名称
        courseName: "",
        // 培训类型
        trainTypeId: "",
      },
      // 培训类型 - 下拉数据
      trainTypeList: [],
      // 获取列表统计数据
      dataStatistics: {},
    };
  },
  created() {
    this.getTrainTypeList();
  },
  computed: {},
  computed: {},
  methods: {
    // 培训类型 - 下拉数据
    getTrainTypeList() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.courseName) {
        params.courseName = this.searchData.courseName;
      }
      if (this.searchData.trainTypeId) {
        params.trainTypeId = this.searchData.trainTypeId;
      }
      this.doFetch({
        url: "/biz/projectCourse/hospital/courseCenter",
        params,
        pageNum,
      });
    },
    // go - 课程详情
    goInfo(item) {
      this.$router.push({
        path: "/web/courseCenterInfo",
        query: {
          courseId: item.courseId,
          projectCourseId: item.projectCourseId,
        },
      });
    },
  },
  watch: {},
};
</script>
<style lang="less" scoped>
.grid-content {
  width: 18%;
  margin: 1%;
  padding: 1% 1.4%;
  box-shadow: 0px 10px 20px #ededed;
  border-radius: 4px;
  float: left;
  transition: all 0.5s;
  position: relative;
  &:hover {
    cursor: pointer;
    /* transform属性 */
    transform: translate(0, -10px);
  }
  p {
    margin-bottom: 6px;
  }
  .g_1 {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    height: 8rem;
  }
  .g_2 {
    font-weight: 600;
    height: 40px;
  }
  .g_3 {
    span {
      color: #f56c6c;
      font-size: 18px;
      padding: 0 4px;
    }
  }
  .g_4 {
    font-size: 13px;
    span {
      color: #484848;
      font-size: 12px;
    }
  }
  .g_5 {
    span {
      color: #409eff;
      padding: 0 4px;
      font-size: 16px;
    }
  }
}
.shuiyin {
  pointer-events: none;
}
.shuiyin-item {
  position: absolute;
  width: 6rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  left: 60%;
  top: 75%;
  font-size: 1.2rem;
  border-radius: 50% 50%;
  border: 2px solid #F0F0F0;
  transform: rotate(-30deg);
  color: #e2e2e2;
}
</style>
